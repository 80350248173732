import { FormattedMessage } from 'react-intl';

import { OpenDataFileDto, PatentSearchRequestDtoSelectedTypesEnum } from '../../service';
import { LOCALES } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key];

export const trimEpLetters = (value: any) => (value?.startsWith('EP') ? value.substring(2) : value);

export const switchLocale = (locale: string) => (locale === LOCALES.ET ? LOCALES.EN : LOCALES.ET);

export const industrialPropertyTypes = [
  {
    value: PatentSearchRequestDtoSelectedTypesEnum.DesignApplication,
    label: <FormattedMessage id="public.search.type-industrial-property.design-application" />,
  },
  {
    value: PatentSearchRequestDtoSelectedTypesEnum.UtilityModel,
    label: <FormattedMessage id="public.search.type-industrial-property.utility-model" />,
  },
  {
    value: PatentSearchRequestDtoSelectedTypesEnum.EuPatent,
    label: <FormattedMessage id="public.search.type-industrial-property.eu-patent" />,
  },
  {
    value: PatentSearchRequestDtoSelectedTypesEnum.AdditionalProtection,
    label: <FormattedMessage id="public.search.type-industrial-property.additional-protection" />,
  },
];

const defaultSelectedTypes = [
  PatentSearchRequestDtoSelectedTypesEnum.DesignApplication,
  PatentSearchRequestDtoSelectedTypesEnum.UtilityModel,
  PatentSearchRequestDtoSelectedTypesEnum.EuPatent,
  PatentSearchRequestDtoSelectedTypesEnum.AdditionalProtection,
];

export const getInitialSelectedTypes = (selectedTypes?:Array<PatentSearchRequestDtoSelectedTypesEnum>) => selectedTypes ?? defaultSelectedTypes;

export const setIds = (data: OpenDataFileDto[], position: number) => data.map((item, index) => ({ ...item, id: position + index }));

export const getSizeInMb = (size: number) => {
  const sizeInMb = size / (1024 * 1024);
  return sizeInMb > 0 ? sizeInMb.toFixed(2) : sizeInMb;
};

export const replaceToUndefinedIfEmpty = (value: any) => (value?.length ? value : undefined);
export const replaceToEmptyArrayIfUndefined = (value: any) => {
  if (value === undefined) {
    return [];
  }
  return value;
};
export const atLeastOneFieldEntered = (values: any) => Object.keys(values).some((key) => replaceToUndefinedIfEmpty(values[key]) !== undefined);
