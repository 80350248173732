/* tslint:disable */
/* eslint-disable */
/**
 * LMIS avalik API
 * LMIS avalik API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@wemakesoftware.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ClassifierConceptName
 */
export interface ClassifierConceptName {
    /**
     * 
     * @type {string}
     * @memberof ClassifierConceptName
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassifierConceptName
     */
    'nameEt'?: string;
}
/**
 * 
 * @export
 * @interface ClassifierDto
 */
export interface ClassifierDto {
    /**
     * 
     * @type {number}
     * @memberof ClassifierDto
     */
    'classifierConceptId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassifierDto
     */
    'classifierName'?: ClassifierDtoClassifierNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ClassifierDto
     */
    'conceptCode'?: string;
    /**
     * 
     * @type {ClassifierConceptName}
     * @memberof ClassifierDto
     */
    'conceptName'?: ClassifierConceptName;
}

export const ClassifierDtoClassifierNameEnum = {
    DecisionType: 'DECISION_TYPE',
    ProceedingStatus: 'PROCEEDING_STATUS',
    ProceedingSubtype: 'PROCEEDING_SUBTYPE',
    RelPersonRole: 'REL_PERSON_ROLE',
    ProceedingType: 'PROCEEDING_TYPE',
    PatentStatus: 'PATENT_STATUS'
} as const;

export type ClassifierDtoClassifierNameEnum = typeof ClassifierDtoClassifierNameEnum[keyof typeof ClassifierDtoClassifierNameEnum];

/**
 * 
 * @export
 * @interface DecisionDto
 */
export interface DecisionDto {
    /**
     * 
     * @type {number}
     * @memberof DecisionDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DecisionDto
     */
    'decisionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionDto
     */
    'status'?: string;
    /**
     * 
     * @type {Array<DecisionFileDto>}
     * @memberof DecisionDto
     */
    'decisionFiles'?: Array<DecisionFileDto>;
}
/**
 * 
 * @export
 * @interface DecisionFileDto
 */
export interface DecisionFileDto {
    /**
     * 
     * @type {number}
     * @memberof DecisionFileDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DecisionFileDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DecisionFileDto
     */
    'mainFile'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralSearchRequestDto
 */
export interface GeneralSearchRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'sortableField'?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneralSearchRequestDto
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSearchRequestDto
     */
    'take': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'sortDirection'?: GeneralSearchRequestDtoSortDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof GeneralSearchRequestDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'applicationNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralSearchRequestDto
     */
    'applicationDate'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralSearchRequestDto
     */
    'registrationDate'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'nameEstonian'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'nameEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'keyword'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchRequestDto
     */
    'patentOffice'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralSearchRequestDto
     */
    'status'?: Array<string>;
}

export const GeneralSearchRequestDtoSortDirectionEnum = {
    Desc: 'DESC',
    Asc: 'ASC'
} as const;

export type GeneralSearchRequestDtoSortDirectionEnum = typeof GeneralSearchRequestDtoSortDirectionEnum[keyof typeof GeneralSearchRequestDtoSortDirectionEnum];

/**
 * 
 * @export
 * @interface GeneralSearchResponseDto
 */
export interface GeneralSearchResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'sortDirection'?: GeneralSearchResponseDtoSortDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof GeneralSearchResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'applicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'applicationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'registrationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'nameEstonian'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'nameEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'keyword'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'patentOffice'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchResponseDto
     */
    'type'?: string;
}

export const GeneralSearchResponseDtoSortDirectionEnum = {
    Desc: 'DESC',
    Asc: 'ASC'
} as const;

export type GeneralSearchResponseDtoSortDirectionEnum = typeof GeneralSearchResponseDtoSortDirectionEnum[keyof typeof GeneralSearchResponseDtoSortDirectionEnum];

/**
 * 
 * @export
 * @interface HyperlinkDto
 */
export interface HyperlinkDto {
    /**
     * 
     * @type {string}
     * @memberof HyperlinkDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HyperlinkDto
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface MilestoneDto
 */
export interface MilestoneDto {
    /**
     * 
     * @type {Array<StepDto>}
     * @memberof MilestoneDto
     */
    'steps'?: Array<StepDto>;
    /**
     * 
     * @type {string}
     * @memberof MilestoneDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MilestoneDto
     */
    'startDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof MilestoneDto
     */
    'endDate'?: number;
    /**
     * 
     * @type {Array<HyperlinkDto>}
     * @memberof MilestoneDto
     */
    'hyperlinks'?: Array<HyperlinkDto>;
}
/**
 * 
 * @export
 * @interface OpenDataFileDto
 */
export interface OpenDataFileDto {
    /**
     * 
     * @type {string}
     * @memberof OpenDataFileDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenDataFileDto
     */
    'fileLink'?: string;
    /**
     * 
     * @type {number}
     * @memberof OpenDataFileDto
     */
    'sizeInBytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof OpenDataFileDto
     */
    'dateUploaded'?: string;
}
/**
 * 
 * @export
 * @interface PageGeneralSearchResponseDto
 */
export interface PageGeneralSearchResponseDto {
    /**
     * 
     * @type {number}
     * @memberof PageGeneralSearchResponseDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGeneralSearchResponseDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGeneralSearchResponseDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<GeneralSearchResponseDto>}
     * @memberof PageGeneralSearchResponseDto
     */
    'content'?: Array<GeneralSearchResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof PageGeneralSearchResponseDto
     */
    'number'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageGeneralSearchResponseDto
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageGeneralSearchResponseDto
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageGeneralSearchResponseDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageGeneralSearchResponseDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGeneralSearchResponseDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGeneralSearchResponseDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageOpenDataFileDto
 */
export interface PageOpenDataFileDto {
    /**
     * 
     * @type {number}
     * @memberof PageOpenDataFileDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpenDataFileDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpenDataFileDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<OpenDataFileDto>}
     * @memberof PageOpenDataFileDto
     */
    'content'?: Array<OpenDataFileDto>;
    /**
     * 
     * @type {number}
     * @memberof PageOpenDataFileDto
     */
    'number'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOpenDataFileDto
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOpenDataFileDto
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOpenDataFileDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpenDataFileDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpenDataFileDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpenDataFileDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PagePatentSearchResponseDto
 */
export interface PagePatentSearchResponseDto {
    /**
     * 
     * @type {number}
     * @memberof PagePatentSearchResponseDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePatentSearchResponseDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePatentSearchResponseDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<PatentSearchResponseDto>}
     * @memberof PagePatentSearchResponseDto
     */
    'content'?: Array<PatentSearchResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof PagePatentSearchResponseDto
     */
    'number'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePatentSearchResponseDto
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PagePatentSearchResponseDto
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePatentSearchResponseDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagePatentSearchResponseDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePatentSearchResponseDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePatentSearchResponseDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageProceedingSearchResponseDto
 */
export interface PageProceedingSearchResponseDto {
    /**
     * 
     * @type {number}
     * @memberof PageProceedingSearchResponseDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProceedingSearchResponseDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProceedingSearchResponseDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ProceedingSearchResponseDto>}
     * @memberof PageProceedingSearchResponseDto
     */
    'content'?: Array<ProceedingSearchResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof PageProceedingSearchResponseDto
     */
    'number'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageProceedingSearchResponseDto
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageProceedingSearchResponseDto
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageProceedingSearchResponseDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageProceedingSearchResponseDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProceedingSearchResponseDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProceedingSearchResponseDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface PatentDto
 */
export interface PatentDto {
    /**
     * 
     * @type {number}
     * @memberof PatentDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'applicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'euPatentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'euSubmissionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'euTranslationSubmissionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'pctApplicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'applicationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'registrationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'publicationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'translationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'spcValidityExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'rightExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'cancellationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'nameEstonian'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'nameEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'patentSummaryTranslation'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'ipcClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'cpcClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'summaryEstonian'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'summaryEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'patentOffice'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'keyword'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'activeIngredient'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'basicPatentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'euPatentApplicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'spcStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'paidValidityExtensionPeriod'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'paidFees'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'paymentSum'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'relatedSpcApplicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'priorityDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentDto
     */
    'decisionDate'?: string;
}
/**
 * 
 * @export
 * @interface PatentFileDto
 */
export interface PatentFileDto {
    /**
     * 
     * @type {number}
     * @memberof PatentFileDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatentFileDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentFileDto
     */
    'fileType'?: PatentFileDtoFileTypeEnum;
}

export const PatentFileDtoFileTypeEnum = {
    Drawing: 'DRAWING',
    DocumentA: 'DOCUMENT_A',
    DocumentB: 'DOCUMENT_B',
    UtilityModelDescriptionDocument: 'UTILITY_MODEL_DESCRIPTION_DOCUMENT',
    EpClaimTranslation: 'EP_CLAIM_TRANSLATION',
    EpDescription: 'EP_DESCRIPTION'
} as const;

export type PatentFileDtoFileTypeEnum = typeof PatentFileDtoFileTypeEnum[keyof typeof PatentFileDtoFileTypeEnum];

/**
 * 
 * @export
 * @interface PatentSearchRequestDto
 */
export interface PatentSearchRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'sortableField'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatentSearchRequestDto
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof PatentSearchRequestDto
     */
    'take': number;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'sortDirection'?: PatentSearchRequestDtoSortDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof PatentSearchRequestDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'applicationNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'applicationDate'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'registrationDate'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'nameEstonian'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'nameEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'keyword'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'patentOffice'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'status'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'pctApplicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'euPatentNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'publicationDate'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'summaryEstonian'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'summaryEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'ipcClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'cpcClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'euPatentApplicationNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'grantDate'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'translationDate'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'patentClaimApplicationDate'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'spcApplicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'spcRegistrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'basicPatentNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'spcValidityExpirationDate'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'rightExpirationDate'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'cancellationDate'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchRequestDto
     */
    'activeIngredient'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'spcStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatentSearchRequestDto
     */
    'selectedTypes'?: Array<PatentSearchRequestDtoSelectedTypesEnum>;
}

export const PatentSearchRequestDtoSortDirectionEnum = {
    Desc: 'DESC',
    Asc: 'ASC'
} as const;

export type PatentSearchRequestDtoSortDirectionEnum = typeof PatentSearchRequestDtoSortDirectionEnum[keyof typeof PatentSearchRequestDtoSortDirectionEnum];
export const PatentSearchRequestDtoSelectedTypesEnum = {
    DesignApplication: 'DESIGN_APPLICATION',
    UtilityModel: 'UTILITY_MODEL',
    EuPatent: 'EU_PATENT',
    AdditionalProtection: 'ADDITIONAL_PROTECTION'
} as const;

export type PatentSearchRequestDtoSelectedTypesEnum = typeof PatentSearchRequestDtoSelectedTypesEnum[keyof typeof PatentSearchRequestDtoSelectedTypesEnum];

/**
 * 
 * @export
 * @interface PatentSearchResponseDto
 */
export interface PatentSearchResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'sortDirection'?: PatentSearchResponseDtoSortDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof PatentSearchResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'applicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'applicationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'registrationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'nameEstonian'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'nameEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'keyword'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'patentOffice'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'pctApplicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'euPatentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'publicationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'summaryEstonian'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'summaryEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'ipcClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'cpcClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'euPatentApplicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'grantDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'translationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'patentClaimApplicationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'spcApplicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'spcRegistrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'basicPatentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'spcValidityExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'rightExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'cancellationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'activeIngredient'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatentSearchResponseDto
     */
    'spcStatus'?: string;
}

export const PatentSearchResponseDtoSortDirectionEnum = {
    Desc: 'DESC',
    Asc: 'ASC'
} as const;

export type PatentSearchResponseDtoSortDirectionEnum = typeof PatentSearchResponseDtoSortDirectionEnum[keyof typeof PatentSearchResponseDtoSortDirectionEnum];

/**
 * 
 * @export
 * @interface ProceedingDto
 */
export interface ProceedingDto {
    /**
     * 
     * @type {number}
     * @memberof ProceedingDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProceedingDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingDto
     */
    'dossierType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingDto
     */
    'proceedingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingDto
     */
    'dossierName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingDto
     */
    'applicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingDto
     */
    'publicationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingDto
     */
    'submissionDate'?: string;
    /**
     * 
     * @type {Array<RelPersonDto>}
     * @memberof ProceedingDto
     */
    'commission'?: Array<RelPersonDto>;
    /**
     * 
     * @type {Array<RelPersonDto>}
     * @memberof ProceedingDto
     */
    'relatedPersons'?: Array<RelPersonDto>;
    /**
     * 
     * @type {Array<DecisionDto>}
     * @memberof ProceedingDto
     */
    'decisions'?: Array<DecisionDto>;
}
/**
 * 
 * @export
 * @interface ProceedingSearchDto
 */
export interface ProceedingSearchDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProceedingSearchDto
     */
    'proceedingType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProceedingSearchDto
     */
    'proceedingSubType'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'proceedingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'dossierName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'dossierRegistrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'dossierApplicationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'submissionDateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'submissionDateEnd'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProceedingSearchDto
     */
    'statuses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'decisionDateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'decisionDateEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProceedingSearchDto
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof ProceedingSearchDto
     */
    'take': number;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'sortableField'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchDto
     */
    'sortDirection'?: ProceedingSearchDtoSortDirectionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProceedingSearchDto
     */
    'selectedTypes'?: Array<string>;
}

export const ProceedingSearchDtoSortDirectionEnum = {
    Desc: 'DESC',
    Asc: 'ASC'
} as const;

export type ProceedingSearchDtoSortDirectionEnum = typeof ProceedingSearchDtoSortDirectionEnum[keyof typeof ProceedingSearchDtoSortDirectionEnum];

/**
 * 
 * @export
 * @interface ProceedingSearchResponseDto
 */
export interface ProceedingSearchResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ProceedingSearchResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchResponseDto
     */
    'proceedingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchResponseDto
     */
    'dossierName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchResponseDto
     */
    'dossierRegistrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchResponseDto
     */
    'dossierApplicationNumber'?: string;
    /**
     * 
     * @type {Array<RelPersonDto>}
     * @memberof ProceedingSearchResponseDto
     */
    'commission'?: Array<RelPersonDto>;
    /**
     * 
     * @type {Array<RelPersonDto>}
     * @memberof ProceedingSearchResponseDto
     */
    'relatedPersons'?: Array<RelPersonDto>;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchResponseDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingSearchResponseDto
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProceedingSearchResponseDto
     */
    'decisionFileId'?: number;
}
/**
 * 
 * @export
 * @interface RelPersonDto
 */
export interface RelPersonDto {
    /**
     * 
     * @type {string}
     * @memberof RelPersonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelPersonDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelPersonDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelPersonDto
     */
    'fullNameOrBusinessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelPersonDto
     */
    'role'?: RelPersonDtoRoleEnum;
}

export const RelPersonDtoRoleEnum = {
    Applicant: 'APPLICANT',
    OwnerRepresentative: 'OWNER_REPRESENTATIVE',
    CommissionMember: 'COMMISSION_MEMBER',
    CommissionMemberChairman: 'COMMISSION_MEMBER_CHAIRMAN'
} as const;

export type RelPersonDtoRoleEnum = typeof RelPersonDtoRoleEnum[keyof typeof RelPersonDtoRoleEnum];

/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface StepDto
 */
export interface StepDto {
    /**
     * 
     * @type {number}
     * @memberof StepDto
     */
    'date'?: number;
    /**
     * 
     * @type {Array<HyperlinkDto>}
     * @memberof StepDto
     */
    'hyperlinks'?: Array<HyperlinkDto>;
    /**
     * 
     * @type {string}
     * @memberof StepDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StepDto
     */
    'dateLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof StepDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StepDto
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StepDto
     */
    'collapsed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StepDto
     */
    'hideLine'?: boolean;
}
/**
 * 
 * @export
 * @interface TimelineDto
 */
export interface TimelineDto {
    /**
     * 
     * @type {Array<MilestoneDto>}
     * @memberof TimelineDto
     */
    'milestones'?: Array<MilestoneDto>;
    /**
     * 
     * @type {number}
     * @memberof TimelineDto
     */
    'actualDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof TimelineDto
     */
    'actualDateLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineDto
     */
    'actualStatusLabel'?: string;
    /**
     * 
     * @type {number}
     * @memberof TimelineDto
     */
    'totalSteps'?: number;
}

/**
 * ClassifierControllerApi - axios parameter creator
 * @export
 */
export const ClassifierControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get classifier concepts by classifier name list
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassifierConceptsByClassifierNames: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getClassifierConceptsByClassifierNames', 'requestBody', requestBody)
            const localVarPath = `/api/classifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassifierControllerApi - functional programming interface
 * @export
 */
export const ClassifierControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClassifierControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get classifier concepts by classifier name list
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassifierConceptsByClassifierNames(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassifierDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassifierConceptsByClassifierNames(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClassifierControllerApi - factory interface
 * @export
 */
export const ClassifierControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClassifierControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary get classifier concepts by classifier name list
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassifierConceptsByClassifierNames(requestBody: Array<string>, options?: any): AxiosPromise<Array<ClassifierDto>> {
            return localVarFp.getClassifierConceptsByClassifierNames(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClassifierControllerApi - object-oriented interface
 * @export
 * @class ClassifierControllerApi
 * @extends {BaseAPI}
 */
export class ClassifierControllerApi extends BaseAPI {
    /**
     * 
     * @summary get classifier concepts by classifier name list
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassifierControllerApi
     */
    public getClassifierConceptsByClassifierNames(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return ClassifierControllerApiFp(this.configuration).getClassifierConceptsByClassifierNames(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesControllerApi - axios parameter creator
 * @export
 */
export const FilesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary download decision file by decisionFileId
         * @param {number} fileId 
         * @param {boolean} [download] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFileById: async (fileId: number, download?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadFileById', 'fileId', fileId)
            const localVarPath = `/api/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (download !== undefined) {
                localVarQueryParameter['download'] = download;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesControllerApi - functional programming interface
 * @export
 */
export const FilesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary download decision file by decisionFileId
         * @param {number} fileId 
         * @param {boolean} [download] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFileById(fileId: number, download?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFileById(fileId, download, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesControllerApi - factory interface
 * @export
 */
export const FilesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary download decision file by decisionFileId
         * @param {number} fileId 
         * @param {boolean} [download] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFileById(fileId: number, download?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.downloadFileById(fileId, download, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesControllerApi - object-oriented interface
 * @export
 * @class FilesControllerApi
 * @extends {BaseAPI}
 */
export class FilesControllerApi extends BaseAPI {
    /**
     * 
     * @summary download decision file by decisionFileId
     * @param {number} fileId 
     * @param {boolean} [download] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public downloadFileById(fileId: number, download?: boolean, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).downloadFileById(fileId, download, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneralControllerApi - axios parameter creator
 * @export
 */
export const GeneralControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get general accepting PatentSearchRequestDto criteria
         * @param {GeneralSearchRequestDto} generalSearchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGeneral: async (generalSearchRequestDto: GeneralSearchRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generalSearchRequestDto' is not null or undefined
            assertParamExists('searchGeneral', 'generalSearchRequestDto', generalSearchRequestDto)
            const localVarPath = `/api/general/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalSearchRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralControllerApi - functional programming interface
 * @export
 */
export const GeneralControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get general accepting PatentSearchRequestDto criteria
         * @param {GeneralSearchRequestDto} generalSearchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGeneral(generalSearchRequestDto: GeneralSearchRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageGeneralSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchGeneral(generalSearchRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralControllerApi - factory interface
 * @export
 */
export const GeneralControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary get general accepting PatentSearchRequestDto criteria
         * @param {GeneralSearchRequestDto} generalSearchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGeneral(generalSearchRequestDto: GeneralSearchRequestDto, options?: any): AxiosPromise<PageGeneralSearchResponseDto> {
            return localVarFp.searchGeneral(generalSearchRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralControllerApi - object-oriented interface
 * @export
 * @class GeneralControllerApi
 * @extends {BaseAPI}
 */
export class GeneralControllerApi extends BaseAPI {
    /**
     * 
     * @summary get general accepting PatentSearchRequestDto criteria
     * @param {GeneralSearchRequestDto} generalSearchRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralControllerApi
     */
    public searchGeneral(generalSearchRequestDto: GeneralSearchRequestDto, options?: AxiosRequestConfig) {
        return GeneralControllerApiFp(this.configuration).searchGeneral(generalSearchRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpenDataControllerApi - axios parameter creator
 * @export
 */
export const OpenDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDump: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('downloadDump', 'name', name)
            const localVarPath = `/api/open-data/dumps/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get available sql dumps
         * @param {GeneralSearchRequestDto} generalSearchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDumps: async (generalSearchRequestDto: GeneralSearchRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generalSearchRequestDto' is not null or undefined
            assertParamExists('getAvailableDumps', 'generalSearchRequestDto', generalSearchRequestDto)
            const localVarPath = `/api/open-data/dumps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalSearchRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenDataControllerApi - functional programming interface
 * @export
 */
export const OpenDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpenDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDump(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDump(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get available sql dumps
         * @param {GeneralSearchRequestDto} generalSearchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableDumps(generalSearchRequestDto: GeneralSearchRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpenDataFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableDumps(generalSearchRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpenDataControllerApi - factory interface
 * @export
 */
export const OpenDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpenDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDump(name: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadDump(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get available sql dumps
         * @param {GeneralSearchRequestDto} generalSearchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDumps(generalSearchRequestDto: GeneralSearchRequestDto, options?: any): AxiosPromise<PageOpenDataFileDto> {
            return localVarFp.getAvailableDumps(generalSearchRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpenDataControllerApi - object-oriented interface
 * @export
 * @class OpenDataControllerApi
 * @extends {BaseAPI}
 */
export class OpenDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataControllerApi
     */
    public downloadDump(name: string, options?: AxiosRequestConfig) {
        return OpenDataControllerApiFp(this.configuration).downloadDump(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get available sql dumps
     * @param {GeneralSearchRequestDto} generalSearchRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenDataControllerApi
     */
    public getAvailableDumps(generalSearchRequestDto: GeneralSearchRequestDto, options?: AxiosRequestConfig) {
        return OpenDataControllerApiFp(this.configuration).getAvailableDumps(generalSearchRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatentControllerApi - axios parameter creator
 * @export
 */
export const PatentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get by application number
         * @param {string} applicationNumber applicationNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByApplicationNumber: async (applicationNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationNumber' is not null or undefined
            assertParamExists('getByApplicationNumber', 'applicationNumber', applicationNumber)
            const localVarPath = `/api/patent/application/{applicationNumber}`
                .replace(`{${"applicationNumber"}}`, encodeURIComponent(String(applicationNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get attached files metadata
         * @param {string} applicationNumber applicationNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesMetadata: async (applicationNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationNumber' is not null or undefined
            assertParamExists('getFilesMetadata', 'applicationNumber', applicationNumber)
            const localVarPath = `/api/patent/{applicationNumber}/files`
                .replace(`{${"applicationNumber"}}`, encodeURIComponent(String(applicationNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get patent accepting PatentSearchRequestDto criteria
         * @param {PatentSearchRequestDto} patentSearchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatent: async (patentSearchRequestDto: PatentSearchRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patentSearchRequestDto' is not null or undefined
            assertParamExists('searchPatent', 'patentSearchRequestDto', patentSearchRequestDto)
            const localVarPath = `/api/patent/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patentSearchRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatentControllerApi - functional programming interface
 * @export
 */
export const PatentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get by application number
         * @param {string} applicationNumber applicationNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByApplicationNumber(applicationNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByApplicationNumber(applicationNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get attached files metadata
         * @param {string} applicationNumber applicationNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesMetadata(applicationNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatentFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesMetadata(applicationNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get patent accepting PatentSearchRequestDto criteria
         * @param {PatentSearchRequestDto} patentSearchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPatent(patentSearchRequestDto: PatentSearchRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePatentSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPatent(patentSearchRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatentControllerApi - factory interface
 * @export
 */
export const PatentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary get by application number
         * @param {string} applicationNumber applicationNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByApplicationNumber(applicationNumber: string, options?: any): AxiosPromise<PatentDto> {
            return localVarFp.getByApplicationNumber(applicationNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get attached files metadata
         * @param {string} applicationNumber applicationNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesMetadata(applicationNumber: string, options?: any): AxiosPromise<Array<PatentFileDto>> {
            return localVarFp.getFilesMetadata(applicationNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get patent accepting PatentSearchRequestDto criteria
         * @param {PatentSearchRequestDto} patentSearchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatent(patentSearchRequestDto: PatentSearchRequestDto, options?: any): AxiosPromise<PagePatentSearchResponseDto> {
            return localVarFp.searchPatent(patentSearchRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatentControllerApi - object-oriented interface
 * @export
 * @class PatentControllerApi
 * @extends {BaseAPI}
 */
export class PatentControllerApi extends BaseAPI {
    /**
     * 
     * @summary get by application number
     * @param {string} applicationNumber applicationNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatentControllerApi
     */
    public getByApplicationNumber(applicationNumber: string, options?: AxiosRequestConfig) {
        return PatentControllerApiFp(this.configuration).getByApplicationNumber(applicationNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get attached files metadata
     * @param {string} applicationNumber applicationNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatentControllerApi
     */
    public getFilesMetadata(applicationNumber: string, options?: AxiosRequestConfig) {
        return PatentControllerApiFp(this.configuration).getFilesMetadata(applicationNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get patent accepting PatentSearchRequestDto criteria
     * @param {PatentSearchRequestDto} patentSearchRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatentControllerApi
     */
    public searchPatent(patentSearchRequestDto: PatentSearchRequestDto, options?: AxiosRequestConfig) {
        return PatentControllerApiFp(this.configuration).searchPatent(patentSearchRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProceedingControllerApi - axios parameter creator
 * @export
 */
export const ProceedingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get proceeding by proceeding number
         * @param {string} proceedingNumber Proceeding number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProceedingByNumber: async (proceedingNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proceedingNumber' is not null or undefined
            assertParamExists('getProceedingByNumber', 'proceedingNumber', proceedingNumber)
            const localVarPath = `/api/proceeding/{proceedingNumber}`
                .replace(`{${"proceedingNumber"}}`, encodeURIComponent(String(proceedingNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get proceedings accepting proceedingSearchDto criteria
         * @param {ProceedingSearchDto} proceedingSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProceedings: async (proceedingSearchDto: ProceedingSearchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proceedingSearchDto' is not null or undefined
            assertParamExists('searchProceedings', 'proceedingSearchDto', proceedingSearchDto)
            const localVarPath = `/api/proceeding/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proceedingSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProceedingControllerApi - functional programming interface
 * @export
 */
export const ProceedingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProceedingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get proceeding by proceeding number
         * @param {string} proceedingNumber Proceeding number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProceedingByNumber(proceedingNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProceedingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProceedingByNumber(proceedingNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get proceedings accepting proceedingSearchDto criteria
         * @param {ProceedingSearchDto} proceedingSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProceedings(proceedingSearchDto: ProceedingSearchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProceedingSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProceedings(proceedingSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProceedingControllerApi - factory interface
 * @export
 */
export const ProceedingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProceedingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary get proceeding by proceeding number
         * @param {string} proceedingNumber Proceeding number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProceedingByNumber(proceedingNumber: string, options?: any): AxiosPromise<ProceedingDto> {
            return localVarFp.getProceedingByNumber(proceedingNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get proceedings accepting proceedingSearchDto criteria
         * @param {ProceedingSearchDto} proceedingSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProceedings(proceedingSearchDto: ProceedingSearchDto, options?: any): AxiosPromise<PageProceedingSearchResponseDto> {
            return localVarFp.searchProceedings(proceedingSearchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProceedingControllerApi - object-oriented interface
 * @export
 * @class ProceedingControllerApi
 * @extends {BaseAPI}
 */
export class ProceedingControllerApi extends BaseAPI {
    /**
     * 
     * @summary get proceeding by proceeding number
     * @param {string} proceedingNumber Proceeding number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProceedingControllerApi
     */
    public getProceedingByNumber(proceedingNumber: string, options?: AxiosRequestConfig) {
        return ProceedingControllerApiFp(this.configuration).getProceedingByNumber(proceedingNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get proceedings accepting proceedingSearchDto criteria
     * @param {ProceedingSearchDto} proceedingSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProceedingControllerApi
     */
    public searchProceedings(proceedingSearchDto: ProceedingSearchDto, options?: AxiosRequestConfig) {
        return ProceedingControllerApiFp(this.configuration).searchProceedings(proceedingSearchDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TimelineControllerApi - axios parameter creator
 * @export
 */
export const TimelineControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeline: async (applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getTimeline', 'applicationId', applicationId)
            const localVarPath = `/api/timeline/application/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimelineControllerApi - functional programming interface
 * @export
 */
export const TimelineControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimelineControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeline(applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimelineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeline(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimelineControllerApi - factory interface
 * @export
 */
export const TimelineControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimelineControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeline(applicationId: string, options?: any): AxiosPromise<TimelineDto> {
            return localVarFp.getTimeline(applicationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimelineControllerApi - object-oriented interface
 * @export
 * @class TimelineControllerApi
 * @extends {BaseAPI}
 */
export class TimelineControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimelineControllerApi
     */
    public getTimeline(applicationId: string, options?: AxiosRequestConfig) {
        return TimelineControllerApiFp(this.configuration).getTimeline(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
}


